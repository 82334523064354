import React, { ReactNode, useRef } from 'react';
import * as Sentry from '@sentry/nextjs';

import { IS_DEV, IS_PRODUCTION } from '../constants';
import { SessionQuery, useSessionQuery } from '../graphql/operations/users.generated';
import { useEvent } from 'react-use';
import dayjs from 'dayjs';
import { useLogout } from '../hooks/useSession';
export const SessionContext = React.createContext<{
  session: SessionQuery['session'] | null;
  loading: boolean;
}>({
  session: null,
  loading: false,
});

/**
 * Only use this hook inside private paths (e.g. src/app/u/*)
 */
const useSessionProvider = (): {
  session: SessionQuery['session'];
  loading: boolean;
} => {
  const lastRefetch = useRef(Date.now());
  const logout = useLogout();
  const { data, loading, refetch } = useSessionQuery({
    notifyOnNetworkStatusChange: false,
    onError: (error) => {
      if (error.networkError) {
        return;
      }

      if (!IS_DEV) {
        logout();
      }
    },
    onCompleted: async (response) => {
      if (IS_PRODUCTION) {
        const LogRocket = await import(/* webpackChunkName: "logrocket" */ 'logrocket');
        LogRocket.identify(response?.session?.user?.id, {
          name: response?.session?.user?.name,
          email: response?.session?.user?.email,
          // @ts-expect-error
          organizationId: response?.session?.organization?.id,
        });
      }

      Sentry.setUser({
        email: response?.session?.user?.email,
        id: response?.session?.user?.id,
        organization: response?.session?.organization?.name,
      });
    },
  });

  useEvent('focus', async () => {
    // Only refetch session if it's been more than 60 seconds since the last refetch
    if (dayjs().diff(lastRefetch.current, 'seconds') > 60) {
      await refetch();
      lastRefetch.current = Date.now();
    }
  });

  // @ts-expect-error
  return { session: data?.session, loading };
};

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const value = useSessionProvider();

  if (value.loading) {
    return null;
  }

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
